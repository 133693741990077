import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Config } from '@environments/config';
import { Stats, Verifications, Wiki } from '@local/client-contracts';
import { ManualPromise } from '@local/common';
import { generateId, isEmbed, isNativeWindow } from '@local/common-web';
import { KeyName, generateTitleUrl, isCtrlOrCommandCombination, isKey, keyCodes } from '@local/ts-infra';
import { DynamicComponentBase, PopupRef, PopupService, UTextareaComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextMenuService } from '@shared/components';
import { EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { EventsService, LogService, WindowService } from '@shared/services';
import { Breadcrumb, BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { windowSizeObserver } from '@shared/utils';
import { stopEvent } from '@shared/utils/elements-util';
import { currentTheme, getTheme } from '@shared/utils/theme.util';
import { EditorComponent } from '@tinymce/tinymce-angular';
import * as DOMPurify from 'dompurify';
import { cloneDeep, concat, isNil, map } from 'lodash';
import { Subject, Subscription, debounceTime, filter, take, takeUntil } from 'rxjs';
import { AppPopupComponent, AppPopupData } from 'src/app/bar/components/app-popup/app-popup.component';
import { BlobsService } from 'src/app/bar/services/blob.service';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { DRAFT_CARD_URL_PARAM, NEW_CARD_URL_PARAM } from 'src/app/bar/services/preview.service';
import { ShowToasterService } from 'src/app/bar/services/show-toaster.service';
import { WikiCardsVerificationsService } from 'src/app/bar/services/wikis/wiki-cards-verifications.service';
import { WikiCardsService } from 'src/app/bar/services/wikis/wiki-cards.service';
import tinymce, { Editor } from 'tinymce';
import { EMBED_SAVE_CARD_KEY_STORAGE } from '../../../preview/helpers/preview.utils';
import { PreviewMode, PreviewWidthBreakpoint } from '../../../preview/model/preview-mode';
import { collectionContent } from '../../helpers/collection.content';
import { WikiCardImageHandler } from '../../helpers/wiki/media-handler/wiki-card-image-handler';
import { WikiCardMediaHandler } from '../../helpers/wiki/media-handler/wiki-card-media-handler';
import { WikiCardVideoHandler } from '../../helpers/wiki/media-handler/wiki-card-video-handler';
import { WikiAttachmentStorageHandler } from '../../helpers/wiki/wiki-attachment-storage-handler';
import { WikiCardFileHelper } from '../../helpers/wiki/wiki-card-file-helper';
import { WikiCardMediaHelper } from '../../helpers/wiki/wiki-card-media-helper';
import { WikiCardMenuActions } from '../../helpers/wiki/wiki-card-menu-actions.helper';
import { DraftSaveModel, WikiCardSaveFlowHandler } from '../../helpers/wiki/wiki-card-save-flow-handler';
import { AutoSaveStatus, SwitchViewState, WikiCardPageMode, WikiCardPopupModel, WikiCardStorageModel } from '../../models/wiki-card-model';
import { WikiCardBlobService } from '../../services/wiki-card-blob.service';
import { WikiCardFileService } from '../../services/wiki-card-file.service';
import { WikiCardPreviewService } from '../../services/wiki-card-preview.service';
import { WikiItemSelectionPopupService } from '../../services/wiki-item-selection-popup.service';
import { CollectionTagSectionComponent } from '../collection-tag-section/collection-tag-section.component';
import { WikiCardFooterComponent } from '../wiki-card-footer/wiki-card-footer.component';
import { WikiCardHeaderLineComponent } from '../wiki-card-header-line/wiki-card-header-line.component';
import './card-editor/card-editor-icons';
import { CardEditorConstants } from './card-editor/card-editor.constants';
import { CardContextMenuOptions, CardEditorService } from './card-editor/card-editor.service';

@UntilDestroy()
@Component({
  selector: 'wiki-card-popup',
  templateUrl: './wiki-card-popup.component.html',
  styleUrls: ['./wiki-card-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiCardPopupComponent implements OnInit, OnDestroy, DynamicComponentBase<any>, AfterViewInit {
  private readonly isEmbed: boolean = isEmbed();
  private readonly MOUSE_LEFT_CLICK_CODE = 0;
  private readonly HEIGHT_TOOLBAR = 48;
  private readonly HEIGHT_SLIDING_TOOLBAR = 38;
  private readonly LINE_HEIGHT = 32;
  private readonly AUTO_SAVE_DEBOUNCE_MS = 2000;
  private readonly IMMEDIATE_SAVE_THRESHOLD_MS = 30000;
  private readonly destroy$ = new Subject();
  readonly STAT_WAITING_TIME: number = 5000;
  readonly COLLECTION_CONTENT = collectionContent;

  //Auto save
  private lastAutoSaveTimestamp: number;
  private lastChangeAutoSave: number;
  autoSaveStatus: AutoSaveStatus;
  private autoSaveSubject$: Subject<number>;
  private autoSaveSub: Subscription;

  private popupRef: PopupRef<WikiCardPopupComponent, WikiCardPopupModel>;
  private cardEditorService: CardEditorService;
  private prevAutoFocus: boolean;
  private _model: WikiCardPopupModel;
  private _containerSize: { width: number; height: number };
  private _title: string;
  private windowSize$ = windowSizeObserver();
  private contextObserver: ResizeObserver;
  private verificationSubscription: Subscription;
  private cardUpdated = new ManualPromise<void>();
  private fileHelper: WikiCardFileHelper;
  private keyHandlerId: string;
  private embedInline: boolean;
  private wikiCardMediaHandler: WikiCardMediaHandler[];
  private wikiCardMediaHelper: WikiCardMediaHelper;
  private accountId: string;
  private prevHtml = null;
  private statTimeoutId: any;
  private verifyPopupRef: PopupRef<AppPopupComponent, AppPopupData>;
  private cardPublished: boolean;

  editorInit: any;
  visibleEditor: boolean;
  closeDropdownTags = 0;
  initialTitle: boolean;
  data: { cardId: string };
  componentFather: any;
  contentLoaded = false;
  breadcrumbsItems: Breadcrumb[];
  pageMode: WikiCardPageMode;
  collection: Wiki.WikiCollection;
  card: Wiki.CardPath | Wiki.Card;
  html = '';
  tags: string[];
  updatedVerification: Verifications.Verification;
  editorFocus: boolean;
  textareaRows = 1;
  publishInProgress: boolean;
  showCloseButton: boolean;
  errorState: boolean;

  @Input() set model(val: WikiCardPopupModel) {
    if (!val) {
      return;
    }
    if (this.model) {
      if (this.pageMode === 'inline') {
        this.showSavedDraftToaster();
      }
      this.finalSaveDraftIfNeeded(true);
    }
    this.resetProperties(true);
    this._model = val;
    if (this.model?.pageMode) {
      this.pageMode = this.model.pageMode;
    }
    this.initModel();
    this.cdr.markForCheck();
  }

  @Input() previewWidthBreakpoint: PreviewWidthBreakpoint;
  @Input() size: PreviewMode = 'popup';

  @Input() set containerSize(val: { width: number; height: number }) {
    this._containerSize = val;
    this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    this.cdr.markForCheck();
  }

  get model(): WikiCardPopupModel {
    return this._model;
  }

  get containerSize(): { width: number; height: number } {
    return this._containerSize;
  }

  get hasRequests(): boolean {
    const hasPolicy: boolean = this.collection?.policy?.disabled === false;
    return !!this.updatedVerification?.requests?.length && hasPolicy;
  }

  private get isNewMode() {
    return this.model?.isNewMode;
  }

  get publishedMode() {
    return this.model?.viewMode === 'published';
  }

  get draftMode() {
    return this.model?.viewMode === 'draft';
  }

  get currentContent() {
    return this.draftMode ? this.card.draft?.content : this.card?.content;
  }

  get currentTitle() {
    return this.draftMode ? this.card.draft?.title : this.card?.title;
  }

  get tooltipText(): string {
    const el: HTMLElement = this.textAreaTitle?.textArea?.nativeElement;
    if (el?.offsetHeight < el?.scrollHeight) {
      return this.title;
    }
    return null;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
    this.cdr.markForCheck();
    this.updateBreadcrumbsAndTitle();
  }

  private get location() {
    const cardId = this.card?.id ?? 'new';
    return `cards/Wiki/${cardId}`;
  }

  @ViewChild(WikiCardHeaderLineComponent) popupHeader: WikiCardHeaderLineComponent;
  @ViewChild(WikiCardFooterComponent) popupFooter: WikiCardFooterComponent;
  @ViewChild(CollectionTagSectionComponent) tagSection: CollectionTagSectionComponent;
  @ViewChild('contextContainer') contextContainer: ElementRef;
  @ViewChild('editor', { read: ElementRef }) editorRef: ElementRef;
  @ViewChild('editorContainer') editorContainer: ElementRef;
  @ViewChild('headerLineRef') headerLineRef: ElementRef;
  @ViewChild('titleRef') titleRef: ElementRef;
  @ViewChild('tagsRef') tagsRef: ElementRef;
  @ViewChild('footerRef') footerRef: ElementRef;
  @ViewChild('editor') editor: EditorComponent;
  @ViewChild('textAreaTitle') textAreaTitle: UTextareaComponent;

  @HostBinding('attr.fullPage')
  get isFullPage() {
    return this.pageMode === 'full';
  }

  @HostBinding('attr.pageMode')
  get pageModeAttr() {
    return this.pageMode;
  }

  @HostBinding('attr.hasRequests')
  get hasRequestsAttr() {
    return this.hasRequests;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    this.finalSaveDraftIfNeeded(true);
  }

  constructor(
    private popupService: PopupService,
    private collectionsService: CollectionsService,
    public wikiCardsService: WikiCardsService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private injector: Injector,
    private keyboardService: KeyboardService,
    private windowService: WindowService,
    private activeRoute: ActivatedRoute,
    private embedService: EmbedService,
    private hubService: HubService,
    private eventsService: EventsService,
    private wikiCardPreviewService: WikiCardPreviewService,
    private showToasterService: ShowToasterService,
    private collectionsHelperService: CollectionsUtilService,
    private wikiCardsVerificationsService: WikiCardsVerificationsService,
    private routerService: RouterService,
    private commandsService: CommandsService,
    private wikiCardFileService: WikiCardFileService,
    private breadcrumbsService: BreadcrumbsService,
    private titleService: Title,
    private wikiCardBlobService: WikiCardBlobService,
    private blobsService: BlobsService,
    private contextMenuService: ContextMenuService,
    private logService: LogService,
    private wikiItemSelectionPopupService: WikiItemSelectionPopupService,
    private wikiCardSaveFlowHandler: WikiCardSaveFlowHandler,
    private wikiAttachmentStorageHandler: WikiAttachmentStorageHandler,
    private wikiCardMenuActions: WikiCardMenuActions,
    private sessionService: SessionService
  ) {
    this.sessionService.current$.pipe(untilDestroyed(this)).subscribe((sessionInfo) => {
      this.accountId = sessionInfo?.workspace?.accountId;
    });
    this.initWikiPopupServices();
  }

  ngOnInit() {
    if (this.popupService.hasDialog && this.pageMode !== 'inline') {
      this.popupRef = this.injector.get(PopupRef);
      this.pageMode = 'popup';
    }
    if (this.popupRef && !this.model) {
      this.model = this.popupRef?.data;
    }
    this.initPageMode();

    this.prevAutoFocus = this.hubService.autoFocus;
    if (!['inline', 'full'].includes(this.pageMode)) {
      this.wikiCardPreviewService.wikiCardPopupStatus.next(true);
    }

    if (this.isEmbed) {
      this.embedService.visible$.pipe(takeUntil(this.destroy$)).subscribe(async (res) => {
        if (!res) {
          this.onDestroy();
        }
      });
    }

    this.registerKeyboardHandler();
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.initNgScrollerHeight();
    if (this.pageMode === 'popup') {
      const body = document.getElementsByTagName('app-root');
      body[0]['style'].pointerEvents = 'none';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  private onDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
    this.hubService.autoFocus = this.prevAutoFocus;
    this.unregisterKeyHandler();
    this.contextObserver?.disconnect();

    this.showSavedDraftToaster();

    if (this.pageMode === 'popup' && !this.isNewMode) {
      this.hubService.removeState('purl', 'popup');
    }
    this.wikiCardFileService.destroyFileHelper(CardEditorService.FILE_HELPER_NAME);

    if (this.pageMode === 'full') {
      this.hubService.readOnly = false;
    } else if (this.pageMode === 'popup') {
      const body = document.getElementsByTagName('app-root');
      body[0]['style'].pointerEvents = 'all';
    }

    this.finalSaveDraftIfNeeded(true);
  }

  private setEditorInit(): any {
    return {
      ...this.cardEditorService.getEditorInit(this.publishedMode),
      body_class: `pageMode-${this.pageMode} ${this.publishedMode ? 'view-mode' : 'edit-mode'}`,
      object_resizing: !this.publishedMode,
      toolbar: this.publishedMode ? false : CardEditorConstants.TOOLBAR,
      quickbars_selection_toolbar: this.publishedMode ? false : CardEditorConstants.QUICKBARS_SELECTION_TOOLBAR,
      content_style: this.publishedMode ? CardEditorConstants.CONTENT_STYLE_VIEW : '',
      contextmenu: this.publishedMode ? CardEditorConstants.CONTEXTMENU_VIEW : CardEditorConstants.CONTEXTMENU,
      file_picker_callback: (callback, value, meta) => this.wikiCardMediaHelper.addImageOrVideoToEditor(callback, meta, this.card.id),
      init_instance_callback: () => {
        setTimeout(() => {
          //add a click event on the toolbar to set focus
          document.querySelector(CardEditorConstants.CLASS_EDITOR_HEADER)?.addEventListener('click', () => {
            this.setEditorFocus(true);
          });
        }, 500); //waiting for the dom to render the editor
      },
      setup: (editor: Editor) => {
        this.cardEditorService.setupTinyMce(editor, this.publishedMode, this.card.id);
        this.addKeyboardToEditor(editor);
        this.addLinkListenersToEditor(editor);
        editor.on('init', () => {
          editor.contentDocument.addEventListener('click', (e) => {
            if (this.fileHelper?.renameAttachmentPopupOpen) {
              this.fileHelper?.renameAttachmentPopupOpen.destroy();
            }
            if (this.draftMode) {
              this.cardEditorService.addNewLineToEnd(e, editor);
            }
            const tagsDropdown = document.getElementsByClassName('p-overlay-content');
            if (tagsDropdown.length) {
              this.closeDropdownTags++;
              this.cdr.markForCheck();
            }
            this.clickAttachment(e);
          });
          editor.contentDocument.addEventListener('auxclick', (e) => {
            this.handleRightClick(e);
          });
          editor.contentDocument.addEventListener('contextmenu', (e) => {
            this.handleRightClick(e);
          });
          editor.contentDocument.addEventListener(
            'dblclick',
            (e) => {
              this.handleDblClick(e);
            },
            true
          );
          if (this.publishedMode) {
            //HACK: enable text selection and copying for code blocks in published mode
            const preElements = editor.getBody().querySelectorAll('pre');
            preElements?.forEach((pre) => {
              pre.removeAttribute('contenteditable');
            });
          }
          this.visibleEditor = true;
          this.cdr.markForCheck();
        });
        editor.on('paste', (event) => {
          if (this.blockOnView(event)) return;
          this.cardEditorService.editorOnPasteEvent(event, editor, this.card.id);
        });
        editor.on('drop', (event) => {
          if (this.blockOnView(event)) return;
          this.cardEditorService.editorOnDropEvent(event, editor, this.card.id);
        });
        editor.on('drag', (event) => {
          if (this.blockOnView(event)) return;
        });
        editor.on('focusin', () => {
          this.setEditorFocus(true);
          this.changeFocus('card-editor-focus');
        });
        editor.on('focusout', (e) => {
          if (this.cardEditorService.canFocusOut() && e.target.nodeName !== 'BODY') {
            this.setEditorFocus(false);
          }
        });
      },
    };
  }

  private resetProperties(modelChanged?: boolean) {
    this.publishInProgress = this.cardPublished = this.initialTitle = this.errorState = false;
    this.cardUpdated = new ManualPromise();
    this.autoSaveStatus = undefined;
    this.prevHtml = null;
    this.visibleEditor = this.contentLoaded = false;
    if (modelChanged && this.statTimeoutId) {
      clearTimeout(this.statTimeoutId);
      this.statTimeoutId = null;
    }
  }

  private async initPageMode() {
    const routeSnapShot: ActivatedRouteSnapshot = this.activeRoute.snapshot;
    const queryParams = routeSnapShot?.queryParams?.purl;
    if (queryParams) {
      this.pageMode = 'popup';
    } else if (routeSnapShot.data.windowMode) {
      this.pageMode = 'window';
    } else if (
      this.pageMode !== 'inline' &&
      routeSnapShot?.params?.param === this.wikiCardsService.FULL_PAGE_CARD_PARAM &&
      !this.isNewMode
    ) {
      this.pageMode = 'full';
    }

    if (this.isEmbed) {
      this.embedInline = await this.embedService?.isInline();
      if (this.embedInline) {
        this.loaderService.ready$.next(true);
        const initFromStorage = await this.initEmbedInline();
        if (initFromStorage) {
          return;
        }
      }
    }

    if (this.pageMode === 'full') {
      this.initFullPage(routeSnapShot);
    } else if (this.pageMode === 'window') {
      this.loaderService.ready$.next(true);
      const cardId = this.data.cardId;
      this.model = { cardId };
    }

    this.cdr.markForCheck();
  }

  private async initEmbedInline(): Promise<boolean> {
    //State after create a card from answer in ticket
    const newAux = localStorage.getItem(EMBED_SAVE_CARD_KEY_STORAGE);
    if (!newAux) {
      return false;
    }
    const model: WikiCardStorageModel = JSON.parse(newAux);
    localStorage.removeItem(EMBED_SAVE_CARD_KEY_STORAGE);
    const card: Wiki.Card = { content: model?.content, title: model?.title, collectionId: model?.collectionId };
    if (model.collectionId) {
      const cardRes = await this.wikiCardSaveFlowHandler.saveCardNewMode(card, model?.collectionId);
      this.model = { cardId: cardRes.id, isNewMode: true };
      return;
    }
    const id = generateId();
    this.wikiItemSelectionPopupService.openWikiItemSelectionPopup(
      'create-card',
      id,
      { item: card, styleOptions: { disableCloseButton: true } },
      false
    );
    this.wikiItemSelectionPopupService.destroySelectPopup$
      .pipe(
        untilDestroyed(this),
        filter((r) => r.processId === id)
      )
      .subscribe((res) => {
        this.model = { cardId: res.newId, isNewMode: true };
      });
    return true;
  }

  private initFullPage(routeSnapShot: ActivatedRouteSnapshot) {
    const cardId = routeSnapShot.params?.id?.split('-')?.slice(-1)?.[0];
    this.hubService.readOnly = true;
    this.model = { cardId };
    this.breadcrumbsService.showGhost = true;
  }

  private callStats() {
    if (this.draftMode || this.statTimeoutId) {
      return;
    }
    this.statTimeoutId = setTimeout(() => {
      if (!this.destroy$.closed && this.card) {
        const stat: Stats.Stat = {
          type: 'card',
          viewers: [{ id: this.accountId, lastViewTime: Date.now(), views: 1 }],
          itemId: this.card.id,
        };
        this.wikiCardsService.upsertStat(stat);
      }
    }, this.STAT_WAITING_TIME);
  }

  private changeFocus(focus: string) {
    this.collectionsService.onFocusChange = focus;
  }

  private async initModel() {
    this.addSubscription();
    this.initDraftMode();
    const fullAppInline = await this.embedService?.isFullApp();
    this.showCloseButton = !['inline', 'full'].includes(this.pageMode) && !(this.isEmbed && this.embedInline && !fullAppInline);

    if (this.model?.cardId) {
      this.cardEditorService.initTinyMce(this.model.cardId, this.sendCommandTelemetryEvent.bind(this));
      this.initCard(this.model.cardId);
    }

    this.cdr.markForCheck();
  }

  private initCard(cardId: string) {
    let initial = true;
    this.wikiCardsService
      .getCard$(cardId)
      .pipe(
        untilDestroyed(this),
        filter((card) => !!card && card.id === cardId)
      )
      .subscribe({
        next: async (card) => {
          //Hack: when opening a card using URL and there is not published version - change mode to draft
          if (initial && this.publishedMode && !card.publishedTime) {
            const canEdit = card.effectiveScopes?.find((e) => e.scope === 'write');
            if (canEdit) {
              this.model.viewMode = 'draft';
            } else {
              this.errorState = true;
              this.cdr.markForCheck();
            }
          }
          if (this.errorState) {
            return;
          }
          this.card = cloneDeep(card);
          await this.onCardUpdate(initial, initial);
          initial = false;
        },
        error: () => {
          this.errorState = true;
          this.cdr.markForCheck();
        },
      });
  }

  private async onCardUpdate(initial?: boolean, modelChanged?: boolean) {
    const content = this.currentContent;
    const hasContent = !isNil(content);
    if (hasContent) {
      let html = DOMPurify.sanitize(cloneDeep(content), { ADD_TAGS: ['iframe'], ADD_ATTR: ['ext'] });
      //HACK to overcome the inline icon path
      html = html?.replaceAll('src="/assets/file-icons', `src="${Config.baseUrl}/assets/file-icons`);
      for (const mediaHandler of this.wikiCardMediaHandler) {
        html = await mediaHandler.insertMediaToHtml(html);
      }
      html = this.fileHelper.retrieveAttachmentsToHtml(html);
      this.html = html || '';
    } else {
      this.contentLoaded = this.visibleEditor = false;
      this.cdr.markForCheck();
    }

    if (initial) {
      if (modelChanged) {
        await this.initOnModelChange();
        //When changing a model - init storage with all the attachments of the draft (and when a card is closed, all the attachments that the user deleted should be deleted from blob)
        this.wikiAttachmentStorageHandler.initCardAttachments(this.card.id, this.card.draft?.attachments || []);
      }
      this.initEditorAndTitle();
      this.initializeAutoSave();
    }

    if (hasContent) {
      this.contentLoaded = true;
      if (!this.cardUpdated.status) {
        this.cardUpdated.resolve();
      }
    }

    this.cdr.markForCheck();
  }

  private initEditorAndTitle() {
    this.setUpEditor();
    const title = this.currentTitle;
    if (title?.length > 0) {
      this.title = title;
    }

    this.updateEditorMode();

    setTimeout(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.calculateTextRowsNumber();
    }, 200);
    this.focusInputOnLoad();

    this.updateBreadcrumbsAndTitle();
    this.breadcrumbsService.showGhost = false;

    if (this.isNewMode) {
      this.initialTitle = true;
    }
  }

  private async initCollectionAndPermission() {
    if (this.card?.collectionId?.length > 0) {
      this.collection = <Wiki.WikiCollection>await this.collectionsService.getById(this.card.collectionId);
      if (this.collection) {
        const canEdit = this.collectionsHelperService.canEdit(this.collection);
        this.model.permissionRole = canEdit ? 'write' : 'read';
      }
    }
  }

  private async initOnModelChange() {
    this.tags = this.card?.tags;
    await this.initCollectionAndPermission();

    if (this.verificationSubscription) {
      this.verificationSubscription.unsubscribe();
      this.verificationSubscription = null;
    }

    if (this.card?.id) {
      this.verificationSubscription = this.wikiCardsService
        .getVerificationByCardId(this.card.id)
        .pipe(untilDestroyed(this))
        .subscribe((v) => {
          this.updatedVerification = v;
          this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
          this.cdr.markForCheck();
        });
    }
  }

  private showSavedDraftToaster(displayOpenButton = true) {
    if (!this.cardPublished && !this.publishInProgress && this.draftMode && (this.autoSaveStatus || this.model.isNewDraft)) {
      this.wikiCardsService.showSavedDraftToaster(this.card?.id, this.card?.draft?.title, displayOpenButton);
    }
  }

  private setUpEditor() {
    this.editorInit = this.setEditorInit();

    const theme = getTheme(currentTheme());
    if (theme === 'dark') {
      if (!this.editorInit.skin.includes('dark')) {
        this.editorInit.skin += '-' + theme;
      }
    } else {
      this.editorInit.skin = this.editorInit.skin.replace('-dark', '');
    }
    this.cdr.markForCheck();
  }

  private updateEditorMode() {
    setTimeout(() => {
      if (this.editor?.editor) {
        this.editor.editor.save();
      }
    }, 0);
  }

  private initWikiPopupServices() {
    this.wikiCardMediaHelper = new WikiCardMediaHelper(
      this.showToasterService,
      this.contextMenuService,
      this.eventsService,
      this.blobsService,
      this.wikiCardBlobService,
      this.wikiCardMenuActions,
      this.wikiAttachmentStorageHandler
    );
    this.wikiCardFileService.destroyFileHelper(CardEditorService.FILE_HELPER_NAME);
    this.fileHelper = this.wikiCardFileService.getOrCreateFileHelper(CardEditorService.FILE_HELPER_NAME);
    this.cardEditorService = new CardEditorService(
      this.eventsService,
      this.hubService,
      this.wikiCardMediaHelper,
      this.contextMenuService,
      this.commandsService,
      this.wikiCardMenuActions,
      this.fileHelper
    );
    this.wikiCardMediaHandler = [
      new WikiCardImageHandler(this.blobsService, this.logService),
      new WikiCardVideoHandler(this.wikiCardBlobService, this.blobsService, this.logService),
    ];
  }

  private blockOnView(e) {
    if (this.publishedMode) {
      stopEvent(e);
      return true;
    }
  }

  private addKeyboardToEditor(editor: Editor) {
    editor.on('keydown', (e) => {
      if (e.code === keyCodes.escape) {
        stopEvent(e);
        this.closePopup();
      }
      if ((isCtrlOrCommandCombination(e, keyCodes.c as KeyName) && this.publishedMode) || this.blockOnView(e)) return;
      if (isKey(e, keyCodes.backspace)) {
        const selectedNode = tinymce.activeEditor.selection.getNode();
        if (
          selectedNode.textContent === '' &&
          selectedNode.firstChild?.nodeName === 'BR' &&
          selectedNode.previousSibling?.nodeName === 'TABLE'
        ) {
          selectedNode.previousSibling.remove();
          tinymce.activeEditor.save();
        }
      }
    });
  }

  private addLinkListenersToEditor(editor: Editor) {
    editor.on('mousedown', (e) => {
      const href = e.target.href || e.target.parentElement?.href;
      if (href && e.button === this.MOUSE_LEFT_CLICK_CODE && e.target?.tagName.toLocaleLowerCase() !== 'img') {
        //Do not allow opening a link by clicking on an image (only by right-clicking)
        window.open(href);
      }
    });
  }

  private focusInputOnLoad() {
    setTimeout(() => {
      if (this.isNewMode && this.pageMode !== 'inline') {
        this.textAreaTitle?.textArea?.nativeElement.setSelectionRange(0, 0);
        this.textAreaTitle?.textArea?.nativeElement.focus();
        this.cdr.markForCheck();
      }
    }, 200);
  }

  private initNgScrollerHeight() {
    this.windowSize$.pipe(untilDestroyed(this)).subscribe(() => {
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
    });
    if (this.contextContainer) {
      const { nativeElement: el } = this.contextContainer;
      this.contextObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.calculateNgScrollerHeight(entries[0]?.target);
      });
      this.contextObserver.observe(el);
    }
  }

  private calculateTextRowsNumber() {
    const el: HTMLElement = this.textAreaTitle?.textArea?.nativeElement;
    const calculatedRows = el?.scrollHeight / this.LINE_HEIGHT;
    if (this.pageMode === 'inline') {
      this.textareaRows = calculatedRows > 2 ? 2 : calculatedRows;
    } else {
      this.textareaRows = calculatedRows > 3 ? 3 : calculatedRows;
    }
    this.cdr.markForCheck();
  }

  private calculateNgScrollerHeight(el) {
    if (!el || this.pageMode !== 'inline') {
      return;
    }

    const elBound = el.getBoundingClientRect();
    const contentHeight = window.innerHeight - elBound.top;
    el['style'].height = contentHeight + 'px';
    this.cdr.markForCheck();
  }

  private addSubscription() {
    this.fileHelper?.saveEditor$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.editor?.editor?.save();
    });
  }

  private clickAttachment(event) {
    const attachment = this.fileHelper.getAttachment(event);
    if (!attachment) return;
    if (event.target.className?.includes(CardEditorConstants.CLASS_DELETE_ATTACHMENT)) {
      attachment.remove();
      this.fileHelper.sendEvent('context_menu.open', this.location, { label: 'delete' });
      this.fileHelper.sendEvent('results.action', this.location, { label: 'delete' });
      this.editor?.editor?.save();
    }
    if (event.target.className?.includes(CardEditorConstants.CLASS_CONTEXT_MENU_ATTACHMENT)) {
      this.openContextMenu(event, { data: attachment, type: 'attachment' });
    }
  }

  private handleRightClick(event: MouseEvent) {
    const selectedText = tinymce.activeEditor?.selection.getContent({ format: 'text' });
    let selectedElement = tinymce.activeEditor.selection.getNode();
    if (selectedElement.tagName.toLocaleLowerCase() === 'span' && selectedElement.querySelector('video')) {
      const id = selectedElement.id || selectedElement.getAttribute('data-mce-p-id'); // tinymce put the id in this attribute
      this.openContextMenu(event, { data: { selectedElement, editor: tinymce.activeEditor, id }, type: 'video' });
    } else if (selectedElement.tagName.toLocaleLowerCase() === 'hr' || event.target?.['tagName'].toLocaleLowerCase() === 'hr') {
      selectedElement = event.target?.['tagName'].toLocaleLowerCase() === 'hr' ? (event.target as HTMLElement) : selectedElement;
      this.openContextMenu(event, { data: { selectedElement, editor: tinymce.activeEditor }, type: 'hr' });
    } else if (['a'].includes(selectedElement.tagName.toLocaleLowerCase())) {
      if (this.publishedMode) {
        stopEvent(event);
        return;
      }
      this.openContextMenu(event, { data: { selectedElement, editor: tinymce.activeEditor }, type: 'a' });
    } else if (['td', 'tr'].includes(selectedElement.tagName.toLocaleLowerCase())) {
      if (this.publishedMode) {
        event.preventDefault();
        return;
      }
    } else if (selectedElement.tagName.toLocaleLowerCase() === 'pre' || selectedText) {
      this.openContextMenu(event, { data: { selectedElement, editor: tinymce.activeEditor }, type: 'code' });
    } else {
      this.auxclickAttachment(event);
    }
  }

  private handleDblClick(event: any) {
    const isCodeBlock = !!event?.target?.closest('pre');
    if (this.publishedMode && isCodeBlock) {
      stopEvent(event);
    }
  }

  private auxclickAttachment(event: MouseEvent) {
    const attachment = this.fileHelper.getAttachment(event);
    if (!attachment) return;
    this.openContextMenu(event, { data: attachment, type: 'attachment' });
  }

  private openContextMenu(event: MouseEvent, contextMenuOp: { type: CardContextMenuOptions; data: any }) {
    event?.preventDefault();
    this.fileHelper.sendEvent('context_menu.open', this.location, { label: 'open_menu' });
    const editor = this.editorContainer?.nativeElement?.getBoundingClientRect() || { x: 0, y: 0 };
    const toolbarOpen = document.getElementsByClassName('tox-toolbar__overflow--open');
    let positionWithToolbar = 0;
    if (!this.publishedMode && this.size !== 'side') {
      positionWithToolbar += this.HEIGHT_TOOLBAR;
      if (toolbarOpen.length) {
        positionWithToolbar += this.HEIGHT_SLIDING_TOOLBAR;
      }
    }
    const x = event.x + editor.x;
    const y = event.y + editor.y + positionWithToolbar;
    let contextMenu;
    switch (contextMenuOp.type) {
      case 'attachment':
        contextMenu = this.fileHelper.openAttachmentContextMenu(
          { x, y },
          contextMenuOp.data,
          this.collection?.id,
          !this.publishedMode,
          this.location,
          { x: editor.x, y: editor.y + positionWithToolbar },
          this.card.id
        );
        break;
      case 'hr':
        contextMenu = this.cardEditorService.openHrContextMenu({ x, y }, contextMenuOp.data, this.location);
        break;
      case 'a':
        contextMenu = this.cardEditorService.openLinkContextMenu({ x, y }, contextMenuOp.data, this.location);
        break;
      case 'code':
        contextMenu = this.cardEditorService.openCodeContextMenu({ x, y }, contextMenuOp.data, this.location);
        break;
      default:
        contextMenu = this.wikiCardMediaHelper.openVideoContextMenu({ x, y }, contextMenuOp.data, this.location, this.draftMode);
        break;
    }
    event?.stopPropagation();
    const isDisabled = this.editor.disabled;
    this.editor.disabled = true;
    contextMenu.destroy$.pipe(untilDestroyed(this)).subscribe(() => {
      if (!isDisabled) {
        this.editor.disabled = false;
      }
    });
  }

  private initializeAutoSave() {
    this.resetAutoSave();
    if (!this.draftMode) {
      return;
    }
    this.lastAutoSaveTimestamp = Date.now();
    this.autoSaveSubject$ = new Subject<number>();
    this.autoSaveSub = this.autoSaveSubject$
      .pipe(
        untilDestroyed(this),
        filter((now) => this.shouldPerformImmediateSave(now)),
        debounceTime(this.AUTO_SAVE_DEBOUNCE_MS)
      )
      .subscribe((now) => {
        this.executeAutoSave(now);
      });
  }

  private resetAutoSave() {
    this.autoSaveStatus = undefined;
    this.autoSaveSub?.unsubscribe();
    this.autoSaveSub = null;
    if (this.autoSaveSubject$) {
      this.autoSaveSubject$.unsubscribe();
      this.autoSaveSubject$ = null;
    }
  }

  private shouldPerformImmediateSave(currentTimestamp: number): boolean {
    const isMoreThan30Seconds = currentTimestamp - this.lastAutoSaveTimestamp > this.IMMEDIATE_SAVE_THRESHOLD_MS;
    if (isMoreThan30Seconds) {
      this.executeAutoSave(currentTimestamp);
      return false;
    }
    return true;
  }

  private async startAutoSave() {
    //Enable to save only after all content is updated (content, title, attachments)
    await this.cardUpdated;
    if (this.publishedMode) {
      return;
    }
    const now = Date.now();
    this.lastChangeAutoSave = now;
    this.autoSaveSubject$.next(now);
    this.autoSaveStatus = 'saving';
    this.cdr.markForCheck();
  }

  private executeAutoSave(time: number) {
    if (!this.draftMode) {
      return;
    }
    const contentText = this.editor?.editor?.getContent({ format: 'text' });
    const saveModel: DraftSaveModel = {
      cardId: this.card.id,
      htmlContent: this.html,
      title: this.title,
      contentText,
      fileHelper: this.fileHelper,
      wikiCardMediaHandler: this.wikiCardMediaHandler,
      wikiCardMediaHelper: this.wikiCardMediaHelper,
      accountId: this.accountId,
    };
    const updatedDraft = this.wikiCardSaveFlowHandler.saveDraftCard(saveModel);
    this.card.draft = updatedDraft;
    this.lastAutoSaveTimestamp = time;
    if (time >= this.lastChangeAutoSave) {
      this.autoSaveStatus = 'saved';
      this.cdr.markForCheck();
    }
  }

  private finalSaveDraftIfNeeded(resetCurrentDraftAttachments?: boolean) {
    if (this.draftMode) {
      if (this.autoSaveStatus === 'saving') {
        this.lastChangeAutoSave = Date.now();
        this.executeAutoSave(this.lastChangeAutoSave);
      }
      this.resetAutoSave();
    }
    if (resetCurrentDraftAttachments) {
      //When changing a model or closing the card - all files removed from the card, their blobIDS should be deleted
      this.resetCardAttachments();
    }
  }

  private resetCardAttachments() {
    if (!this.card) {
      return;
    }
    const allAttachments = concat(this.card.draft?.attachments || [], this.card.attachments || []);
    const currentAttachmentIds = map(allAttachments, 'blobId');
    this.wikiAttachmentStorageHandler.resetCardAttachments(this.card.id, currentAttachmentIds);
  }

  private closePopupWindow() {
    if (isNativeWindow()) {
      this.finalSaveDraftIfNeeded(true);
      this.windowService.close();
      return;
    }
    this.embedService.close();
  }

  private initDraftMode() {
    if (this.model?.viewMode) {
      return;
    }
    const isDraftMode = this.activeRoute.snapshot?.queryParams?.[DRAFT_CARD_URL_PARAM];
    this.model.viewMode = isDraftMode ? 'draft' : 'published';
    if (isDraftMode) {
      const isNewContentMode = this.activeRoute.snapshot?.queryParams?.[NEW_CARD_URL_PARAM];
      if (isNewContentMode) {
        this.model.isNewMode = true;
      }
      this.routerService.removeQueryParam([DRAFT_CARD_URL_PARAM, NEW_CARD_URL_PARAM], true);
      this.model.isNewDraft = this.model?.isNewMode;
    }
  }

  private setEditorFocus(value: boolean) {
    this.editorFocus = value;
    this.cdr.markForCheck();
  }

  private removeToolbar() {
    const floatingToolbar = document.getElementsByClassName('tox-pop')[0];
    if (floatingToolbar) {
      floatingToolbar.remove();
    }
  }

  onSwitchView(view: SwitchViewState, saveIfNeeded = true) {
    this.changeFocus('switchView');
    switch (view) {
      case 'editCard': {
        const updatedDraft = this.wikiCardSaveFlowHandler.createDraft(this.card, this.accountId);
        this.card.draft = updatedDraft;
        this.wikiAttachmentStorageHandler.initCardAttachments(this.card.id, this.card.draft?.attachments || []);
        this.model.viewMode = 'draft';
        this.model.isNewDraft = true;
        break;
      }
      case 'closeDraft': {
        if (saveIfNeeded) {
          this.showSavedDraftToaster(false);
          this.finalSaveDraftIfNeeded();
        }
        this.model.viewMode = 'published';
        this.model.isNewDraft = false;
        break;
      }
      case 'editDraft': {
        this.model.viewMode = 'draft';
        break;
      }
    }
    this.resetProperties();
    this.cdr.markForCheck();
    setTimeout(() => {
      this.onCardUpdate(true, false);
    }, 0);
  }

  async updateBreadcrumbsAndTitle(newPath?: Wiki.Path[]) {
    if (newPath?.length) {
      (this.card as Wiki.CardPath).path = newPath;
      const collectionId = newPath?.[0]?.id;
      const collectionChanged = collectionId !== this.collection.id;
      if (collectionChanged) {
        this.card.collectionId = collectionId;
        this.collection = <Wiki.WikiCollection>await this.collectionsService.getById(collectionId);
      }
    }
    const collectionUrl = generateTitleUrl('wikis', this.collection?.title, this.collection?.id);
    const breadcrumbsItems: Breadcrumb[] = this.wikiCardsService.buildCardBreadcrumbs((this.card as Wiki.CardPath)?.path, collectionUrl);
    if (this.pageMode === 'full') {
      breadcrumbsItems.unshift({
        title: 'Wikis',
        path: 'wikis',
        icon: {
          type: 'font-icon',
          value: 'icon-wiki',
        },
      });
      breadcrumbsItems.push({
        title: this.title,
        path: this.wikiCardsService.getCardUrl(this.title, this.card?.id, true),
        icon: {
          type: 'font-icon',
          value: 'icon-card',
        },
      });
      this.breadcrumbsService.items = breadcrumbsItems.filter((b) => b.title);
      this.titleService.setTitle(`${this.title} | Unleash.so`);
      this.breadcrumbsItems = null;
    } else {
      this.breadcrumbsItems = breadcrumbsItems;
    }
    this.cdr.markForCheck();
  }

  closePopup() {
    if (['inline', 'full'].includes(this.pageMode)) return;

    if (this.pageMode === 'window') {
      this.closePopupWindow();
      return;
    }

    this.popupRef?.close();
    this.wikiCardPreviewService?.popupRef?.close();
    return true;
  }

  onInitEditor() {
    this.updateEditorMode();
    this.prevHtml = this.html;
    setTimeout(() => {
      if (this.draftMode && !this.isNewMode && ['popup', 'full'].includes(this.size)) {
        this.editor?.editor?.focus();
      }
      this.calculateNgScrollerHeight(this.contextContainer?.nativeElement);
      this.hubService.autoFocus = false;
      this.callStats();
    }, 0);
  }

  async duplicateWikiCard() {
    this.wikiItemSelectionPopupService.duplicateCard(this.card);
  }

  onHtmlChange(event) {
    const cleanHtml = this.fileHelper.removeClassFromAttachment(event || '', CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    const cleanPrevHtml = this.fileHelper.removeClassFromAttachment(this.prevHtml || '', CardEditorConstants.CLASS_ACTIVE_ATTACHMENT);
    if (this.prevHtml === null || cleanHtml === cleanPrevHtml) {
      this.prevHtml = cleanHtml;
      return;
    }
    this.prevHtml = cleanHtml;
    this.startAutoSave();
  }

  titleChanged($event) {
    this.calculateTextRowsNumber();
    this.title = $event;
    this.initialTitle = false;
    this.startAutoSave();
  }

  onInputClick() {
    this.changeFocus('card-title');
    this.cdr.markForCheck();
  }

  inputKeyDown(event) {
    if (isKey(event, keyCodes.enter) || isKey(event, keyCodes.ArrowDown)) {
      if (isKey(event, keyCodes.enter)) {
        stopEvent(event);
      }
      this.editor?.editor?.focus();
      this.textAreaTitle?.onBlurEvent(event);
    }
    event.stopPropagation();
  }

  async publishCard(eventLabel: 'mouse_click' | 'keyboard' = 'mouse_click') {
    this.publishInProgress = true;
    this.cdr.markForCheck();
    this.removeToolbar();
    this.finalSaveDraftIfNeeded(true);
    //When create card with content (create from answer) need to update the contentText
    if (!this.card.draft?.contentText) {
      this.card.draft.contentText = this.editor?.editor?.getContent({ format: 'text' });
    }
    const verifyPopupClose = new ManualPromise<void>();
    const publishedTime = Date.now();
    this.eventsService.event('wikis.publish_card', {
      label: eventLabel,
      location: {
        title: this.hubService.currentLocation,
      },
    });
    this.wikiCardSaveFlowHandler
      .publishCard(this.card.draft, this.card.id, this.card.collectionId, publishedTime)
      .then(async (updatedCard) => {
        if (['inline', 'full'].includes(this.pageMode)) {
          await verifyPopupClose;
          this.card = { ...(this.card || {}), ...cloneDeep(updatedCard) };
          this.model.isNewMode = false;
          this.cardPublished = true;
          this.onSwitchView('closeDraft', false);
        }
      })
      .catch(() => {
        this.initializeAutoSave();
      })
      .finally(() => {
        this.publishInProgress = false;
        this.cdr.markForCheck();
      });
    const isVerifier = this.updatedVerification?.policy?.isVerifier;
    if (isVerifier) {
      this.openVerifyPopup(verifyPopupClose, publishedTime);
    } else {
      if (!verifyPopupClose.status) {
        verifyPopupClose.resolve();
      }
      this.closePopup();
    }
  }

  private openVerifyPopup(verifyPopupClose: ManualPromise<void>, publishedTime: number) {
    if (this.verifyPopupRef) {
      this.verifyPopupRef.destroy();
    }
    const position: ConnectedPosition[] = [{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }];
    this.verifyPopupRef = this.popupService.open<AppPopupComponent, AppPopupData>(
      'center',
      AppPopupComponent,
      {
        message: 'Do you want to verify the card?',
        showButtons: true,
        rightButtonStyle: { size: 96, type: 'primary' },
        leftButtonStyle: { size: 96 },
        content: {
          secondaryButton: 'Not now',
          primaryButton: 'Verify',
        },
        messageStyle: { fontSize: '14px' },
      },
      { position, closeOnClickOut: false }
    );
    this.verifyPopupRef.destroy$.pipe(take(1)).subscribe(() => {
      this.verifyPopupRef = null;
      if (!verifyPopupClose.status) {
        verifyPopupClose.resolve();
      }
      this.closePopup();
    });
    this.verifyPopupRef.compInstance.primaryButton.pipe(take(1)).subscribe(() => {
      this.wikiCardsService.updateVerificationStatus('Verified', [this.card.id], false, publishedTime);
    });
  }

  isExternalMenuOpen(event) {
    const menu = document.getElementsByClassName('tox-tiered-menu');
    const tooltip = document.getElementsByClassName('tox-pop');
    const toolbar = document.getElementsByClassName('tox-toolbar');
    const smallMenu = document.getElementsByClassName('tox-menu');
    const dialog = document.getElementsByClassName('tox-dialog-wrap');
    const isTagsMultiSelectOpen = this.tagSection?.isMultiSelectOpen();
    const isToolbarClick = !!event?.target?.closest('.tox.tox-tinymce-inline') || !!event?.target?.closest('.tox-tinymce-aux');

    return (
      isToolbarClick ||
      isTagsMultiSelectOpen ||
      menu.length > 0 ||
      tooltip.length > 0 ||
      toolbar.length > 0 ||
      smallMenu.length > 0 ||
      dialog.length > 0 ||
      this.fileHelper?.renameAttachmentPopupOpen ||
      this.contextMenuService.currentOpenRefs() > 0 ||
      this.fileHelper?.contextMenuOpened ||
      this.wikiCardMediaHelper?.contextMenuOpened ||
      this.cardEditorService?.contextMenuOpened ||
      event?.target?.tagName.toLowerCase() === 'a' // click on image with link in editor
    );
  }

  onTagChange($event) {
    this.tags = $event;
    this.wikiCardSaveFlowHandler.updatePublishedCard({ tags: this.tags, id: this.card.id, collectionId: this.card.collectionId });
    this.card.tags = this.tags;
  }

  openRequestsHistory() {
    this.changeFocus('card-request-history');
    this.wikiCardsVerificationsService.openRequestHistoryPopup({ ...this.card, verification: this.updatedVerification });
  }

  async openExternal(externalClick: boolean) {
    this.finalSaveDraftIfNeeded(true);
    this.eventsService.event('collections.full_size', {
      location: { title: this.getTelemetryLocation() },
    });
    let url = `${this.wikiCardsService.getCardUrl(this.currentTitle || 'Untitled', this.card.id)}`;
    if (this.draftMode) {
      url += `?${DRAFT_CARD_URL_PARAM}=true`;
    }
    if (this.isEmbed) {
      this.embedService.openUrl(url);
      this.popupRef?.close();
      return;
    }
    if (this.pageMode === 'window') {
      this.windowService.maximize(externalClick);
      return;
    }
    this.popupRef?.close();
    this.routerService.navigateByUrl(url);
  }

  outsideClick() {
    if (this.cardEditorService.canFocusOut()) {
      this.setEditorFocus(false);
    }
  }

  //@@@ KEY HANDLING

  private registerKeyboardHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.handleKeys(keys, event);
    }, 9);
    this.cdr.markForCheck();
  }

  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private async handleKeys(keys: Array<KeyName>, event: KeyboardEvent) {
    if (!this.visibleEditor) {
      return;
    }
    if (isKey(event, keyCodes.escape)) {
      const success = this.closePopup();
      if (success) {
        event.stopPropagation();
      }
    }
    if (isKey(event, keyCodes.tab)) {
      event.stopPropagation();
    }
    if (isCtrlOrCommandCombination(event, keyCodes.g as KeyName)) {
      this.duplicateWikiCard();
      stopEvent(event);
    }
  }

  private getTelemetryLocation() {
    const cardId = this.card?.id ?? 'new';
    return `cards/Wiki/${cardId}`;
  }

  ////@@@@@ TELEMETRY

  sendCommandTelemetryEvent(actionName: string) {
    this.eventsService.event('collections.card_commands_menu', {
      label: actionName,
      location: {
        title: this.location,
      },
    });
  }
}
