<div #wrapper tabindex="0" class="wrapper" [class]="{ blur: userInputOptions }">
  <ng-container [ngSwitch]="true">
    <div class="results-header results-title-header" *ngIf="firstSearchCompleted && currentNode?.id != 'search' && isEmptyState === false">
      <div class="results-header-title">{{ currentNode?.title }}</div>
      <ng-container *ngTemplateOutlet="resultsHeaderButtons"></ng-container>
    </div>
    <div class="results-header" *ngIf="!paramsSelector" [class.results-header-preview]="isPreviewMode && pageType !== 'collections'">
      <ng-container
        *ngIf="
          pageType && !isEmptyState && !['connect-pc', 'connect-apps'].includes(view) && !isOnlyAnswerResultInLauncher;
          else tempHeader
        "
      >
        <div class="filter-box-container" [ngClass]="{ 'filter-box-container-default': resultsService.isDefaultSearch() }">
          <ng-container>
            <results-filters-box
              *ngIf="!displayAppsEmptyState; else noApps"
              id="filters"
              [filters]="resultFilters"
              (filterChange)="onFilterChange($event)"
              (clearAll)="clearAllFilters($event)"
              [enableClearAll]="true"
              (openWithKeyboard)="filtersOpenWithKeyboard = true"
              [separateLayout]="true"
              (closeFilters)="onCloseFilters()"
              (indexOpen$)="resultFiltersOpen($event)"
            >
            </results-filters-box>
            <ng-template #noApps>
              <apps-empty-state
                *ngIf="resultsService.isDefaultSearch() && displayedContext?.searchCompleted"
                [view]="!!displayedContext?.items.length ? 'Banner' : 'EmptyState'"
              ></apps-empty-state>
            </ng-template>
          </ng-container>
        </div>
        <ng-container *ngIf="currentNode?.id === 'search'">
          <ng-container *ngTemplateOutlet="resultsHeaderButtons"></ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #tempHeader>
      <div class="temp-header-height" *ngIf="!isEmptyState && !isAskUnleash"></div>
    </ng-template>
    <ng-container *ngSwitchCase="!!paramsSelector">
      <param-selections
        [model]="paramsSelector"
        (invoke)="onInvoke($event, 'local-action')"
        (destroy)="backToActions($event)"
      ></param-selections>
    </ng-container>
    <ng-container *ngIf="!paramsSelector && ('collections' !== pageType || !collectionTypes.includes(pageType)) && showBrowseBar">
      <ng-container *ngTemplateOutlet="browseBar"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="view == 'syncing'">
      <syncing-state [data]="syncingStateData"></syncing-state>
    </ng-container>
    <ng-container *ngSwitchCase="view == 'connect-apps'">
      <ng-container *ngIf="connectApps">
        <ng-container *ngIf="!isLauncher; then appEmptyState; else launcherEmptyState"></ng-container>
      </ng-container>

      <ng-template #appEmptyState>
        <connect-apps [model]="connectApps" [canReconnectApp]="isOwnerOrAdmin"></connect-apps>
      </ng-template>

      <ng-template #launcherEmptyState>
        <launcher-connect-apps
          [model]="connectApps"
          [canReconnectApp]="isOwnerOrAdmin"
          (connect)="openAppFromLauncher($event, true)"
        ></launcher-connect-apps>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="view == 'connect-pc'">
      <div class="connect-pc-wrapper"><connect-pc [nativeAppStatus]="nativeAppStatus" [isLauncher]="isLauncher"></connect-pc></div>
    </ng-container>
    <ng-container *ngSwitchDefault #results>
      <div (click)="checkPreviewVisibility($event); $event.stopPropagation()" data-cy="result-wrapper" class="results-wrapper">
        <as-split
          class="results-split-wrapper"
          direction="horizontal"
          unit="percent"
          (dragEnd)="previewDragEnd($event)"
          [ngClass]="{ 'limit-content': isPreviewMode }"
        >
          <as-split-area
            order="0"
            [size]="100 - previewViewModel.previewWidth"
            [style]="{ backgroundColor: 'transparent', height: '100%' }"
            [visible]="splitAreaPreviewModel.visibleResults"
            [ngStyle]="splitAreaPreviewModel.visibleResults ? { 'min-width': RESULTS_MIN_SIZE + 'px' } : {}"
          >
            <ng-container>
              <div #resultsSplitArea class="results-container" [ngClass]="{ border: isPreviewMode }">
                <ng-scrollbar
                  class="infinite-scroll"
                  visibility="hover"
                  *ngIf="(!isEmptyResultsOrAnswers || (showGhost$ | async)) && !emptyRelevantPeopleQuery; else noResults"
                >
                  <ng-container *ngIf="pageType === 'analytics'">
                    <analytics></analytics>
                  </ng-container>
                  <div
                    class="search-results"
                    #scrollElement
                    scrollViewport
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="0"
                    [scrollWindow]="false"
                    (scroll)="onScrollStart($event)"
                    (scrolled)="updateResultsLimit()"
                    [ngClass]="isPreviewMode ? 'infinite-scroll-side' : 'infinite-scroll'"
                  >
                    <div class="infinite-scroll-container">
                      <ng-container *ngIf="isLoadingAnswers">
                        <result-answer-ghost *ngIf="!SPECIAL_VIEWS.includes(pageType)" [showLoadingText]="true"></result-answer-ghost>
                      </ng-container>
                      {{ track() }}
                      <ng-container
                        *ngFor="
                          let item of displayedContext?.items || [] | slice : 0 : resultsLimit;
                          index as i;
                          let first = first;
                          trackBy: itemIdTrack
                        "
                      >
                        <ng-container *ngTemplateOutlet="resultsTypes; context: { item: item, i: i, first: first }"></ng-container>
                      </ng-container>

                      <ng-container *ngIf="showGhost$ | async">
                        <div class="ghost-container" *ngIf="!SPECIAL_VIEWS.includes(pageType)">
                          <result-ghost *ngFor="let i of 20 | fill"></result-ghost>
                        </div>
                      </ng-container>
                      <refine-search-message *ngIf="resultsLimit === 100 && displayedContext?.items?.length > 100"></refine-search-message>
                    </div>
                  </div>
                  <gradient-overflow *ngIf="!isLauncher && resultsLimit < 100"></gradient-overflow>
                </ng-scrollbar>
                <ng-template #noResults>
                  <go-links-empty-state
                    [isLauncher]="isLauncher"
                    class="no-links-go-link"
                    *ngIf="view === 'view' && pageType === 'golinks' && noGoLinks"
                    (onCreateNewGoLink)="onCreateGoLinkItemClick()"
                  ></go-links-empty-state>

                  <wiki-empty-state *ngIf="noWikis"> </wiki-empty-state>

                  <empty-state
                    *ngIf="noCards || noDrafts"
                    [createNewOption]="pageType === 'cards'"
                    [type]="pageType === 'cards' ? 'card' : 'draft'"
                    (onCreated)="openCard('mouse_click')"
                  ></empty-state>

                  <collections-empty-state *ngIf="noCollections"> </collections-empty-state>

                  <assistants-empty-state *ngIf="noAssistants"></assistants-empty-state>
                  <ng-container *ngIf="noCollectionItems">
                    <div class="collection-empty-state">
                      <u-icon class="sad-doc" [model]="{ type: 'font', value: 'icon-sad-doc' }" [styles]="{ fontSize: '28px' }"></u-icon>
                      <p class="text">Nothing to see yet</p>
                    </div>
                  </ng-container>

                  <app-empty-state
                    *ngIf="emptyStateModel"
                    [title]="emptyStateModel.title"
                    [subtitle]="emptyStateModel.subtitle"
                    [svgUrl]="emptyStateModel.svgUrl"
                    [animation]="emptyStateModel.animation"
                    [showAdditionalSubtitle]="pageType === 'favorites'"
                    [isLauncher]="isLauncher"
                  >
                    <ng-container subtitle>
                      <div class="empty-state-subtitle" *ngIf="pageType === 'favorites'">
                        Hit<key-binding class="subtitle-key" [readonly]="true" [keys]="[isMac ? 'command' : 'Control', 'f']"></key-binding
                        >when on a resource to mark it as a favorite
                      </div>
                    </ng-container>
                  </app-empty-state>

                  <relevant-people-empty-state *ngIf="emptyRelevantPeopleQuery"></relevant-people-empty-state>
                  <div
                    class="no-results"
                    *ngIf="
                      isEmptyResults() &&
                      !resultsService.isDefaultSearch() &&
                      !noCollections &&
                      !noWikis &&
                      !noCards &&
                      !emptyRelevantPeopleQuery &&
                      !noAssistants
                    "
                  >
                    <h1 class="no-results-title headline-1">
                      No results {{ displayedContext?.items?.length ? 'for' : '' }} <br />
                      <span *ngFor="let tag of displayedContext?.workCtx?.tags || []; index as i"
                        ><q class="headline-1 bold">{{ tag.title }}</q>
                        <span
                          *ngIf="(displayedContext?.workCtx?.tags?.length && i !== displayedContext?.workCtx?.tags?.length - 1) || query"
                        >
                          and
                        </span>
                      </span>
                      <q class="headline-1 bold" *ngIf="query">
                        {{ query | truncate : 30 }}
                      </q>
                    </h1>
                    <apps-empty-state *ngIf="displayAppsEmptyState" [view]="'NoResults'" [isQuery]="true"></apps-empty-state>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </as-split-area>
          <as-split-area
            #previewSplitArea
            order="2"
            [size]="previewViewModel.previewWidth"
            [maxSize]="splitAreaPreviewModel.maxPreviewWidth"
            [minSize]="splitAreaPreviewModel.minPreviewWidth"
            [visible]="splitAreaPreviewModel.visiblePreview"
            [style]="{
              display:
                displayedContext?.items?.length === 0 ||
                !isActivePreview ||
                ((isSmallScreen || isLauncher) && previewViewModel.previewState != 'popup')
                  ? 'none'
                  : 'grid'
            }"
          >
            <ng-container>
              <preview
                [telemetrySearch]="{
                  search: {
                    clientSearchId: displayedContext?.clientSearchId,
                    sessionId: displayedContext.sessionId,
                    searchId: searchId
                  }
                }"
                [index]="selectedIndex"
                [isLauncher]="isLauncher"
                [previewViewModel]="previewViewModel"
                [previewMode]="previewViewModel.previewState"
                [previewType]="selected?.action?.type"
                [previewWidth]="previewRef?.nativeElement?.offsetWidth"
                [previewHeight]="resultsRef?.nativeElement?.offsetHeight"
                [items]="selectedForPreview$ | async"
                (invoke)="onInvoke($event, 'result')"
                (sizeChanged)="updatePreviewState($event)"
                (invokeDirty)="onInvokeDirty($event)"
              ></preview>
            </ng-container>
          </as-split-area>
        </as-split>
      </div>
    </ng-container>
  </ng-container>
</div>

<input-popup *ngIf="userInputOptions" [model]="userInputOptions" (proceed)="onUserInput($event)" (close)="onUserInput(null)"></input-popup>

<ng-template #browseBar>
  <browse-bar
    *ngIf="showBrowseBar; else noBrowseBar"
    [model]="barModel"
    [isLauncher]="isLauncher"
    [ngClass]="{
      'browse-bar-one-line': displayTogglePreview || displaySortDropDown
    }"
    [enableWindowResize]="true"
    [focus]="(hubService.focusPosition$ | async) === 'filters'"
  >
  </browse-bar>
  <ng-template #noBrowseBar>
    <div
      *ngIf="['search', 'favorites', 'collections'].includes(pageType) && !showFilterBox && !displayTogglePreview"
      class="no-browse-bar"
    ></div>
  </ng-template>
</ng-template>

<ng-template #resultsHeaderButtons>
  <ng-container *ngIf="showButtonsContainer">
    <div
      class="results-header-buttons"
      [ngClass]="{
        'results-header-buttons-search-page': currentNode?.id === 'search'
      }"
    >
      <u-input
        #smallInputSearch
        *ngIf="currentNode?.id != 'search' && isEmptyState === false"
        class="small-input-search"
        [ngClass]="{ 'small-input-blur': !smallInputSearchFocus && hubService.inputQuery }"
        [inlineExtraIcon]="{ type: 'font', value: 'icon-search-icon-fixed' }"
        [placeholder]="'Search'"
        (onChange)="onQuickSearch($event)"
        [model]="hubService.inputQuery"
        [isSearchInput]="true"
        [ignoreEnter]="true"
        [extraIcon]="!smallInputSearchFocus && !hubService.inputQuery ? { type: 'font', value: 'icon-slash' } : null"
        (onFocus)="smallInputSearchFocus = true"
        (onBlur)="smallInputSearchFocus = false"
      ></u-input>
      <ng-container *ngIf="displaySortDropDown">
        <u-dropdown
          #sortDropdown
          *ngIf="currentNode?.id === 'search'; else iconSortButton"
          [options]="sortOptions"
          optionLabel="label"
          (onChange)="onChangeSort($event)"
          [styles]="{ width: '152px' }"
          [dropdownSelectIcon]="{ type: 'font', value: 'icon-sort-list-down' }"
          class="sort-drop-down"
          [indicationSelected]="true"
          [hasValues]="!!searchParamsService.getSort()"
          [disabled]="disableButtons"
          [selectedValue]="sortSelected"
        >
        </u-dropdown>
        <ng-template #iconSortButton>
          <u-button
            #sortButtonRef
            class="sort-icon"
            elementSize="small"
            fontIcon="sort-list-down"
            type="secondary"
            [class.sort-active]="!!sortService?.popupRefSort"
            [class.active]="!!searchParamsService.getSort()"
            [styles]="{ fontSize: '14px', height: '32px', width: '32px' }"
            (onClick)="openSort(sortButtonRef)"
            [pTooltip]="sortTooltipText"
            tooltipPosition="bottom"
            tooltipStyleClass="u-tooltip"
            [disabled]="disableButtons"
          ></u-button
        ></ng-template>
      </ng-container>
      <u-button
        *ngIf="displayTogglePreview"
        (onClick)="toggleResultsPreview()"
        type="secondary"
        class="button-result toggle-preview"
        [onlyIcon]="true"
        [ngClass]="{
          active: isPreviewMode,
          focused: previewKeyboardService.componentFocused?.compId === PreviewFocusEnum.PreviewToggle
        }"
        [pTooltip]="getTogglePreviewTooltip()"
        tooltipPosition="left"
        tooltipStyleClass="u-tooltip"
        [disabled]="disableButtons"
      >
        <u-icon [model]="{ type: 'font', value: 'icon-toggle-preview-light' }" [styles]="{ fontSize: '30px' }"></u-icon>
      </u-button>
      <u-button
        *ngIf="pageType === 'cards' && currentNode?.id !== 'search'"
        class="add-icon"
        elementSize="small"
        [label]="smallScreen ? '' : 'Create Card'"
        type="primary"
        fontIcon="plus"
        [styles]="{ fontSize: '14px', width: smallScreen ? '32px' : '130px' }"
        (click)="openCard('mouse_click')"
        pTooltip="C"
        tooltipPosition="bottom"
        tooltipStyleClass="u-tooltip"
      ></u-button>
      <u-button
        *ngIf="showCreateGoLinkButton()"
        (onClick)="onCreateGoLinkItemClick()"
        class="add-icon"
        type="primary"
        fontIcon="plus"
        elementSize="small"
        [iconStyles]="{ fontSize: '14px', 'font-weight': 'bold' }"
        [styles]="{ fontSize: '14px', width: smallScreen ? '32px' : '131px' }"
        [label]="smallScreen ? '' : 'New Go Link'"
        tooltipPosition="left"
        tooltipStyleClass="u-tooltip"
      >
      </u-button>
    </div>
  </ng-container>
</ng-template>

<ng-template #peopleItem let-item="item" let-i="i">
  <ng-container *ngIf="item.kind === 'relevant-people'; else normalPeople">
    <relevant-people-item
      #resultItem
      [class.disabled]="item.deleted"
      [class.selected]="selectedIndex === i"
      [class.hovered]="hoveredIndex === i"
      [ngClass]="{ 'preview-mode': isPreviewMode, hovered: hoveredIndex === i }"
      [sessionId]="displayedContext.sessionId"
      [clientSearchId]="displayedContext.clientSearchId"
      [searchId]="searchId"
      [selected]="selectedIndex === i"
      [model]="item"
      tabindex="-1"
      [index]="i"
      [resultsIndex]="item.resultIndex"
      (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
      (click)="updateSelectedIndex(i)"
      [attr.data-index]="i"
      [query]="query"
      [listName]="listName(item)"
      [pageType]="pageType"
      [previewState]="previewViewModel.previewState"
      [previewable]="isPreviewClickAction(item.action)"
      (contextOpen)="openContextMenu(i, item)"
      (contextClose)="closeContextMenu()"
      (mouseover)="hoveredIndex = i"
      (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
      class="result-item"
      [viewMode]="previewViewModel.previewState === 'side' ? 'small' : item.settings.viewMode"
    >
    </relevant-people-item>
  </ng-container>
  <ng-template #normalPeople>
    <people-view-item
      #resultItem
      [class.disabled]="item.deleted"
      [class.selected]="selectedIndex === i"
      [class.hovered]="hoveredIndex === i"
      [ngClass]="{ 'preview-mode': previewViewModel.previewState === 'side', hovered: hoveredIndex === i }"
      [sessionId]="displayedContext.sessionId"
      [clientSearchId]="displayedContext.clientSearchId"
      [searchId]="searchId"
      [selected]="selectedIndex === i"
      [model]="item"
      tabindex="-1"
      [index]="i"
      [resultsIndex]="item.resultIndex"
      (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
      (click)="updateSelectedIndex(i)"
      [attr.data-index]="i"
      [query]="query"
      [listName]="listName(item)"
      [pageType]="pageType"
      [previewState]="previewViewModel.previewState"
      [previewable]="isPreviewClickAction(item.action)"
      (contextOpen)="openContextMenu(i, item)"
      (contextClose)="closeContextMenu()"
      (mouseover)="hoveredIndex = i"
      (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
      class="result-item"
      [showResultSections]="{
        showFavoriteAction: pageType === 'favorites'
      }"
      [viewMode]="previewViewModel.previewState === 'side' || !item.settings?.viewMode ? 'line' : item.settings?.viewMode"
    ></people-view-item>
  </ng-template>
</ng-template>

<ng-template #resultsTypes let-item="item" let-i="i" let-first="first">
  <ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'header'">
      <result-header
        [attr.data-cy]="'result-header-' + item.title"
        #resultItem
        [showRightButton]="item.showButton && [selected?.type, hoveredItem?.type].includes('recent-search')"
        [model]="item"
        [class.preview-mode]="isPreviewMode"
        [index]="i"
        [attr.data-index]="i"
        [footer]="item.isFooter"
        [selected]="selectedIndex === i && item.selectable"
        (clicked)="headerClicked($event)"
        (buttonClicked)="clearRecentSearches()"
        [focusedGroup]="focusedGroup"
        (focusGroupClick)="focusGroupClick($event)"
        (mouseover)="onHoverItem(i)"
        (mouseout)="hoveredIndex = null"
        [isCollectionHeader]="collectionTypes.includes(pageType)"
        [showLauncherButton]="first && showLauncherButton"
        [launcherButtonText]="'Open Collection in App'"
        (openCollectionExternal)="openCollectionExternal()"
        [ngClass]="{
          'non-footer': !item.isFooter,
          selectable: item.selectable,
          'result-item': item.selectable
        }"
      ></result-header>
    </ng-container>
    <ng-container *ngSwitchCase="'static-search-item'">
      <static-search-item
        #resultItem
        [attr.data-index]="i"
        [model]="item"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        (onInvoke)="onStaticItemInvoke($event, item)"
        class="result-item first-item-margin-top"
        [class.search-answer]="item.invokeType === 'search-answer'"
        [ngClass]="{
          'add-margin': item?.classList?.includes('add-margin')
        }"
      ></static-search-item>
    </ng-container>
    <ng-container *ngSwitchCase="'recent-search'">
      <recent-search-item
        #resultItem
        [model]="item"
        class="result-item block-item default-item"
        [index]="i"
        [keyboardOn]="keyboardOn"
        [selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (clicked)="recentSearchClicked($event)"
        (click)="updateSelectedIndex(i)"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        [attr.data-index]="i"
      ></recent-search-item>
    </ng-container>
    <ng-container *ngSwitchCase="'suggestions'">
      <suggestions-item
        [attr.data-cy]="'filter-suggestion-item'"
        #resultItem
        [class.selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i); onSuggestionItemClicked(item)"
        [model]="item"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        (mouseout)="focusedGroup = null"
        class="result-item default-item"
        [attr.data-index]="i"
      >
      </suggestions-item>
    </ng-container>
    <ng-container *ngSwitchCase="'embed-item'">
      <embed-item
        #resultItem
        [model]="item"
        class="result-item block-item"
        [index]="i"
        [selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i); onEmbedItemClick(item)"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
      ></embed-item>
    </ng-container>
    <ng-container *ngSwitchCase="'web-search'">
      <web-search-results-item
        #resultItem
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        class="result-item"
        [selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        (click)="updateSelectedIndex(i); onWebSearchItemClick(item)"
        [model]="item"
        [firstResult]="i === 0 || isFirstItemInGroup(i)"
        [engine]="item.engine"
        [attr.data-index]="i"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
      >
      </web-search-results-item>
    </ng-container>
    <ng-container *ngSwitchCase="'browser-tab'">
      <browser-tab-item
        #resultItem
        class="result-item"
        [class.keyboardOn]="keyboardOn"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i); onTabClick('mouse_click')"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        [model]="item"
        [selected]="selectedIndex === i"
        [attr.data-index]="i"
        [query]="query"
      >
      </browser-tab-item>
    </ng-container>
    <ng-container *ngSwitchCase="'browser-bookmark'">
      <browser-bookmark-item
        #resultItem
        class="result-item"
        [class.keyboardOn]="keyboardOn"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i); onBookmarkClick('mouse_click')"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        [model]="item"
        [selected]="selectedIndex === i"
        [attr.data-index]="i"
        [query]="query"
      >
      </browser-bookmark-item>
    </ng-container>
    <ng-container *ngSwitchCase="'goto'">
      <go-to-item
        #resultItem
        [selected]="selectedIndex === i"
        [class.selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i); onGoToItemClick(item)"
        [model]="item"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        (mouseout)="focusedGroup = null"
        class="result-item first-item-margin-top"
        [attr.data-index]="i"
        [query]="query"
      >
      </go-to-item>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <error-item
        #resultItem
        [selected]="selectedIndex === i"
        [class.selected]="selectedIndex === i"
        [model]="item"
        (invoke)="onInvoke($event)"
        tabindex="0"
        class="result-item first-item-margin-top"
        (click)="updateSelectedIndex(i)"
        [attr.data-index]="i"
        [index]="i"
      ></error-item>
    </ng-container>
    <ng-container *ngSwitchCase="'calculator'">
      <calc-result-item
        #resultItem
        [selected]="selectedIndex === i"
        [class.selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (disclaimerClick)="onDisclaimerClick($event)"
        (click)="updateSelectedIndex(i); onCalcItemClick(i)"
        [model]="item"
        class="result-item"
        [attr.data-index]="i"
        [index]="i"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
      ></calc-result-item>
    </ng-container>
    <ng-container *ngSwitchCase="'go-link'">
      <go-links-view-item
        #resultItem
        class="result-item first-item-margin-top"
        [class.disabled]="item.deleted"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        [sessionId]="displayedContext.sessionId"
        [clientSearchId]="displayedContext.clientSearchId"
        [searchId]="searchId"
        [selected]="selectedIndex === i"
        [model]="item"
        [visit]="item.visit"
        tabindex="-1"
        [index]="i"
        [resultsIndex]="item.resultIndex"
        (invoke)="updateSelectedIndex(i); onInvoke($event, 'go-link')"
        (click)="updateSelectedIndex(i)"
        [attr.data-index]="i"
        [query]="query"
        [listName]="listName(item)"
        (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
        (contextClose)="closeContextMenu()"
        (mouseover)="hoveredIndex = i"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
        [pageType]="pageType"
        [showResultSections]="{
          showTags: item.source !== 'favorites',
          showBullets: item.source != 'favorites',
          showFavoriteAction: pageType === 'favorites',
          showFavoriteIcon: pageType != 'favorites',
        }"
        [previewState]="previewViewModel.previewState"
      ></go-links-view-item>
    </ng-container>
    <ng-container *ngSwitchCase="'gallery-group'">
      <gallery-group
        #resultItem
        [items]="item.items"
        [componentFocused]="{ focused: selectedIndex === i, direction: arrowDirection }"
        [ngClass]="{ 'preview-mode': isPreviewMode, hovered: hoveredIndex === i }"
        [sessionId]="displayedContext.sessionId"
        [clientSearchId]="displayedContext.clientSearchId"
        [searchId]="searchId"
        [model]="item"
        tabindex="-1"
        [index]="i"
        [resultsIndex]="item.resultIndex"
        (onUpdateSelectedIndex)="updateInnerIndex($event, i)"
        (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
        (click)="updateSelectedIndex(i)"
        (focusedFinish)="galleryGroupFocusFinished($event.direction, $event.event)"
        [attr.data-index]="i"
        [query]="query"
        [listName]="listName(item)"
        [pageType]="pageType"
        [previewState]="previewViewModel.previewState"
        [previewable]="isPreviewClickAction(item.action)"
        (contextOpen)="openContextMenu(i, item)"
        (contextClose)="closeContextMenu()"
        (mouseover)="hoveredIndex = i"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
        (changeHeightItem)="updateHeightItem(i)"
        class="result-item"
      ></gallery-group>
    </ng-container>
    <ng-container *ngSwitchCase="'person'">
      <ng-container *ngTemplateOutlet="peopleItem; context: { item: item, i: i }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'assistant'">
      <assistant-item
        #resultItem
        class="result-item"
        [attr.data-index]="i"
        [assistant]="item"
        [selected]="selectedIndex === i"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        (click)="updateSelectedIndex(i)"
        (mouseover)="hoveredIndex = i"
        (contextOpen)="updateSelectedIndex(i); hoveredIndex = index"
        (contextClose)="closeContextMenu()"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
      >
      </assistant-item>
    </ng-container>
    <ng-container *ngSwitchCase="'collection'">
      <collection-item
        #resultItem
        class="result-item first-item-margin-top"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        [attr.data-index]="i"
        [collection]="item"
        [selected]="i === selectedIndex"
        (tagClicked)="onCollectionTagClick($event)"
        (click)="updateSelectedIndex(i)"
        (dblclick)="openCollection(item, 'search-view')"
        tabindex="-1"
        [index]="i"
        (mouseover)="hoveredIndex = i"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
        [viewMode]="'search-result'"
        [isClickable]="isLauncher"
        (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
        (contextClose)="closeContextMenu()"
        [index]="i"
        [pageType]="'search'"
        [showResultSections]="{
          showFavoriteAction: pageType === 'favorites',
          showFavoriteIcon: pageType != 'favorites'
        }"
      >
      </collection-item>
    </ng-container>
    <ng-container *ngSwitchCase="'collection-url'">
      <static-result-item
        #resultItem
        class="result-item"
        [class.preview-mode]="isPreviewMode"
        [model]="item"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [attr.data-index]="i"
        [index]="i"
        [showResultSections]="{
          showBullets: true,
          calcBullets: true,
          showContextMenu: true,
          showFavoriteAction: pageType === 'favorites'
        }"
        (invoke)="updateSelectedIndex(i); onInvoke($event, 'collection-url')"
        (click)="updateSelectedIndex(i)"
        (contextOpen)="updateSelectedIndex(i); hoveredIndex = index"
        (contextClose)="hoveredIndex = null"
        (mouseover)="hoveredIndex = i"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
      >
      </static-result-item>
    </ng-container>
    <ng-container *ngSwitchCase="'collection-file'">
      <static-result-item
        #resultItem
        class="result-item"
        [class.preview-mode]="isPreviewMode"
        [model]="item"
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [attr.data-index]="i"
        [index]="i"
        [showResultSections]="{
          showBullets: true,
          calcBullets: true,
          showContextMenu: true,
          showFavoriteAction: pageType === 'favorites',
          showPreviewIcon: true,
          showSummaryIcon: true
        }"
        (invoke)="updateSelectedIndex(i); onInvoke($event, 'collection-url')"
        (click)="updateSelectedIndex(i)"
        (contextOpen)="updateSelectedIndex(i); hoveredIndex = index"
        (contextClose)="hoveredIndex = null"
        (mouseover)="hoveredIndex = i"
        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
      >
      </static-result-item>
    </ng-container>
    <ng-container *ngSwitchCase="'collection-header'">
      <static-result-item
        [class.preview-mode]="isPreviewMode"
        #resultItem
        [style.margin-top]="i === 0 ? '0px' : '6px'"
        class="result-item header-item"
        [model]="item"
        [index]="i"
        [lightTitle]="true"
        [attr.data-index]="i"
        [showResultSections]="{ showContextMenu: false, isEditable: false }"
        (invoke)="onInvoke($event, 'collection-header')"
        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
      >
      </static-result-item>
    </ng-container>
    <ng-container *ngSwitchCase="'result'">
      <ng-container
        *ngIf="(view === 'view' && ['files', 'people', 'mail'].includes(pageType)) || item.action?.type === 'people'; else result"
      >
        <ng-container [ngSwitch]="item.action?.type">
          <ng-container *ngSwitchCase="'files'">
            <files-view-item
              #resultItem
              class="result-item"
              [class.disabled]="item.deleted"
              [class.selected]="selectedIndex === i"
              [class.hovered]="hoveredIndex === i"
              [ngClass]="{ 'preview-mode': previewViewModel.previewState === 'side', hovered: hoveredIndex === i }"
              [sessionId]="displayedContext.sessionId"
              [clientSearchId]="displayedContext.clientSearchId"
              [searchId]="searchId"
              [selected]="selectedIndex === i"
              [model]="item"
              tabindex="-1"
              [index]="i"
              [resultsIndex]="item.resultIndex"
              (invoke)="
                updateSelectedIndex(i); $event?.command?.type === 'preview' ? updateSelectedIndex(i) : null; onInvoke($event, 'result')
              "
              (click)="onPreviewItemClick(item, i); $event.stopPropagation()"
              [attr.data-index]="i"
              [query]="query"
              [listName]="listName(item)"
              [previewable]="isPreviewClickAction(item.action)"
              [pageType]="pageType"
              [previewState]="previewViewModel.previewState"
              (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
              (contextClose)="closeContextMenu()"
              (mouseover)="hoveredIndex = i"
              (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
            ></files-view-item>
          </ng-container>
          <ng-container *ngSwitchCase="'mail'">
            <mail-view-item
              #resultItem
              [class.disabled]="item.deleted"
              [class.selected]="selectedIndex === i"
              [class.hovered]="hoveredIndex === i"
              [ngClass]="{ 'preview-mode': previewViewModel.previewState === 'side', hovered: hoveredIndex === i }"
              [class.keyboardOn]="keyboardOn"
              [sessionId]="displayedContext.sessionId"
              [clientSearchId]="displayedContext.clientSearchId"
              [searchId]="searchId"
              [selected]="selectedIndex === i"
              [model]="item"
              [inboxFilterOnly]="inboxFilterOnly"
              [preview]="previewViewModel.previewState === 'side'"
              tabindex="-1"
              [index]="i"
              [resultsIndex]="item.resultIndex"
              (invoke)="updateSelectedIndex(i); onInvoke($event, 'result')"
              (click)="updateSelectedIndex(i)"
              [attr.data-index]="i"
              [query]="query"
              [listName]="listName(item)"
              [pageType]="pageType"
              [previewState]="previewViewModel.previewState"
              [previewable]="isPreviewClickAction(item.action)"
              (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
              (contextClose)="closeContextMenu()"
              (mouseover)="hoveredIndex = i"
              (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
            ></mail-view-item>
          </ng-container>
          <ng-container *ngSwitchCase="'people'">
            <ng-container *ngTemplateOutlet="peopleItem; context: { item: item, i: i }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #result>
        <result-item
          #resultItem
          [isLauncher]="isLauncher"
          [class.first-item-of-type]="isFirstItemInGroup(i)"
          [class.disabled]="item.deleted"
          [class.selected]="selectedIndex === i"
          [class.hovered]="hoveredIndex === i"
          [class.keyboardOn]="keyboardOn"
          [class.preview-mode]="isPreviewMode"
          [class.isCard]="isCard(item)"
          [sessionId]="displayedContext.sessionId"
          [clientSearchId]="displayedContext.clientSearchId"
          [searchId]="searchId"
          [selected]="selectedIndex === i"
          [pageType]="pageType"
          [previewState]="previewViewModel.previewState"
          [previewable]="isPreviewClickAction(item.action)"
          class="result-item first-item-margin-top"
          [model]="item"
          [summaryResourceId]="item.resourceId"
          tabindex="0"
          [index]="i"
          [resultsIndex]="item.resultIndex"
          (invoke)="onInvoke($event, 'result', i)"
          (updateSelectedIndex)="updateSelectedIndex(i)"
          (heightItem)="updateHeightSnippetItem($event, i)"
          (click)="updateSelectedIndex(i)"
          [attr.data-index]="i"
          [query]="query"
          [listName]="listName(item)"
          (contextOpen)="openContextMenu(i, item)"
          (contextClose)="closeContextMenu()"
          (titleChanged)="titleChanged($event, item)"
          (mouseover)="hoveredIndex = i"
          (mouseout)="!onOpenContextMenu && !onOpenSummaryPopup ? (hoveredIndex = null) : null; focusedGroup = null"
          [resourceList]="item.resourceList"
          [hovered]="hoveredIndex === i"
          click-invoker
          [showResultSections]="{
            showFavoriteAction: pageType === 'favorites',
            showTags: item.source !== 'favorites',
            showSemantic: item.source === 'link-resources',
            isEditable: item.source === 'wiki-collection-items',
            showContextMenu: true,
            showBullets: true,
            showSummaryIcon: true,
            trimSubtitleLength: true,
            showPreviewIconContextMenu: !isCard(item),
            showSingleLineView: isPreviewMode && isCard(item),
            showFeedback:
              item.source === 'link-resources' && hubService.searchMethod === 'Search-On-Enter' && !feedbackSentItems?.includes(item.id)
          }"
          [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
          (onFeedbackSent)="feedbackSentItems.push($event)"
        ></result-item>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'local-action'">
      <local-actions-item
        #resultItem
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [class.preview-mode]="isPreviewMode"
        [selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [isLauncher]="isLauncher"
        [model]="item"
        [index]="i"
        [isClickable]="isLauncher"
        [attr.data-index]="i"
        class="result-item first-item-margin-top"
        (invoke)="updateSelectedIndex(i); onActionItemClick(i)"
        (click)="updateSelectedIndex(i); isLauncher ? onActionItemClick(i) : ''"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        (mouseout)="focusedGroup = null"
      ></local-actions-item>
    </ng-container>
    <ng-container *ngSwitchCase="'create-go-link'">
      <create-go-link-item
        #resultItem
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.preview-mode]="isPreviewMode"
        [isLauncher]="isLauncher"
        [model]="item"
        [index]="i"
        [attr.data-index]="i"
        class="result-item first-item-margin-top"
        (click)="onCreateGoLinkItemClick(item.url)"
        (invoke)="onCreateGoLinkClick(item)"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        (mouseout)="focusedGroup = null"
      ></create-go-link-item>
    </ng-container>
    <ng-container *ngSwitchCase="'add-to-collection'">
      <create-go-link-item
        #resultItem
        [class.selected]="selectedIndex === i"
        [class.hovered]="hoveredIndex === i"
        [selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.preview-mode]="isPreviewMode"
        [isLauncher]="isLauncher"
        [model]="item"
        [index]="i"
        [attr.data-index]="i"
        class="result-item first-item-margin-top"
        (click)="onAddToCollectionItemClick(item.url, 'mouse_click')"
        (invoke)="onAddToCollectionItemClick(item, 'mouse_click')"
        (mouseover)="hoveredIndex = i"
        (mouseout)="hoveredIndex = null"
        (mouseout)="focusedGroup = null"
      ></create-go-link-item>
    </ng-container>
    <ng-container *ngSwitchCase="'answer'">
      <result-answer-item
        *ngIf="!isEmptyResultsOrAnswers && isLauncherSearchAnswerDone && !isLoadingAnswers"
        #resultItem
        [typingViewMode]="generatingState ? 'static' : 'interactive'"
        [isLauncher]="isLauncher"
        [class.selected]="selectedIndex === i"
        [class.keyboardOn]="keyboardOn"
        [class.preview-mode]="isPreviewMode"
        [model]="item"
        class="result-item"
        [attr.data-index]="i"
        [searchId]="searchId"
        [index]="i"
        (invoke)="onInvoke($event, 'result', i)"
        (answerTypedFinished)="onAnswerTypedFinished()"
        [sessionId]="displayedContext.sessionId"
        [clientSearchId]="displayedContext.clientSearchId"
        [isFeedbackOpen]="isAnswerFeedbackOpen"
        (feedbackShow)="showAnswerFeedback($event, i)"
      ></result-answer-item>
    </ng-container>
    <ng-container *ngSwitchCase="'answer-feedback'">
      <answer-feedback
        [model]="item"
        [searchId]="searchId"
        [index]="i"
        [query]="query"
        (closeFeedback)="hideAnswerFeedback(i)"
        (focusTextarea)="focusAnswerFeedbackText($event)"
      ></answer-feedback>
    </ng-container>
  </ng-container>
</ng-template>
