import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chats, Filters, Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { generateId, getAssistantTitle, isEmbed } from '@local/common-web';
import { LogService, ServicesRpcService, WindowService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, filter, firstValueFrom, map } from 'rxjs';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatsRpcInvoker } from 'src/app/bar/services/invokers/chats.rpc-invoker';
import { CHAT_PAGE_PATH } from 'src/app/bar/utils/constants';
import { AnswerSearchItem } from '../../results';
import { ChatResourcesService } from './chat-resources.service';
import { AssistantChatData, CurrentChatData, NEW_CHAT_ID } from '../model';
import { WorkspacesService } from 'src/app/bar/services';

@Injectable()
export class ChatsService {
  private readonly TEMP_CHAT_SESSION_STORAGE_KEY = 'temp_chat_session';
  private logger: Logger;
  private service: Chats.Service;
  private _all$ = new ReplaySubject<Chats.ChatSessionsByAssistant>(1);
  private _currentChat$ = new BehaviorSubject<CurrentChatData>(null);
  private isEmbed = isEmbed();
  private localTempChat: Chats.Chat;
  private localTempQuery: string;
  private _assistantsForChat$ = new ReplaySubject<{ [key: string]: AssistantChatData }>(1);

  @observable
  get assistantsForChat$(): Observable<{ [key: string]: AssistantChatData }> {
    return this._assistantsForChat$;
  }

  @observable
  get currentChat$(): Observable<CurrentChatData> {
    return this._currentChat$;
  }

  @observable
  get currentAssistantId$(): Observable<string> {
    return this._currentChat$.pipe(
      filter((chat) => !!chat),
      map((chatData) => chatData.assistant?.id)
    );
  }

  private set currentChat(chat: CurrentChatData) {
    this._currentChat$.next(chat);
  }

  private get currentChat(): CurrentChatData {
    return this._currentChat$.value;
  }

  @observable
  private get all$(): Observable<Chats.ChatSessionsByAssistant> {
    return this._all$.asObservable();
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private routerService: RouterService,
    private windowService: WindowService,
    private filtersService: FiltersService,
    private hubService: HubService,
    private chatResourcesService: ChatResourcesService,
    private experiencesService: ExperiencesService,
    private workspaceService: WorkspacesService
  ) {
    this.logger = logger.scope('ChatsService');
    this.service = services.invokeWith(ChatsRpcInvoker, 'chats');
    this.service.all$.subscribe((all) => {
      this._all$.next(all);
    });
    this.initAssistantsData();
    this.handleChatRoute();
  }

  private initAssistantsData() {
    this.experiencesService.all$
      .pipe(
        filter((items) => !!items),
        map((items) => items?.filter((item) => item.experienceType === 'general' && item.impersonateUser))
      )
      .subscribe(async (items) => {
        const assistants: { [key: string]: AssistantChatData } = {};
        items.forEach((item) => {
          assistants[item.id] = {
            id: item.id,
            emoji: item.properties?.emoji,
            name: getAssistantTitle(item),
            description:
              item.properties?.description ||
              'Chat and ask questions to explore everything you need to know from your assistant’s knowledge base',
            canAccess: ['creator', 'editor', 'viewer'].includes(item?.permissionRole),
            icon: !item.properties?.emoji ? { type: 'font', value: 'icon-assistant' } : null,
          };
        });
        const defaultAssistant = await this.getWorkspaceAssistantData();
        assistants[defaultAssistant.id] = defaultAssistant;
        this._assistantsForChat$.next(assistants);
      });
  }

  private async getWorkspaceAssistantData(): Promise<AssistantChatData> {
    const workspace = await firstValueFrom(this.workspaceService.current$);
    const icon = this.workspaceService.getLogo();
    return {
      id: undefined,
      name: workspace?.name,
      icon: { type: 'img', value: icon },
      description: 'Chat and ask questions to uncover everything you want to know from your company’s knowledge base',
      isDefault: true,
    };
  }

  async goToChatPageWithHistory(answerItem: AnswerSearchItem, assistantId?: string) {
    const { query } = answerItem;
    const timestamp = Date.now();
    const answer: Chats.AssistantMessage = this.createMessageFromAnswer(answerItem, timestamp);
    const currentFilters: Filters.Values = this.filtersService.allFilters;
    const filtersToQuestion: Filters.Values = await this.filtersService.transformFiltersForDisplay(currentFilters);
    const question = {
      content: query,
      filters: filtersToQuestion,
      timestamp,
    };
    const chatId = generateId();
    const currentChat = await this.findChatByAssistant(assistantId);
    const currentChatId = currentChat?.id;
    const historyItem: Chats.ChatHistoryItem = {
      chatId,
      userMessage: question,
      assistantMessage: answer,
    };
    this.service.createSession(assistantId, chatId, currentChatId).then(() => {
      this.createHistoryMessage(historyItem);
    });
    const tempChatSession: Chats.Chat = { id: chatId, assistantId, chatHistory: [historyItem] };
    const isLauncher = await this.hubService.getIsLauncher();
    if (isLauncher) {
      localStorage.setItem(this.TEMP_CHAT_SESSION_STORAGE_KEY, JSON.stringify(tempChatSession));
    } else {
      this.localTempChat = tempChatSession;
    }
    this.openChat(assistantId);
  }

  async openChat(assistantId?: string, query?: string) {
    if (query) {
      this.localTempQuery = query;
    }
    let chatUrl = `/${CHAT_PAGE_PATH}`;
    if (assistantId) {
      chatUrl += `/${assistantId}`;
    }
    const currentFilters = this.filtersService.allFilters;
    if (!isEmpty(currentFilters)) {
      const filtersUrl = this.filtersService.getFiltersAsUrlParams(currentFilters);
      chatUrl += `?${filtersUrl}`;
    }
    const isLauncher = await this.hubService.getIsLauncher();
    if (this.isEmbed) {
      if (isLauncher) {
        return this.hubService.openStandardEmbed(chatUrl, true);
      }
      return this.routerService.navigateByUrl(chatUrl, { replaceUrl: true });
    }
    if (isLauncher) {
      return this.windowService.switchToStandard(chatUrl);
    }
    return this.routerService.navigateByUrl(chatUrl);
  }

  createSession(assistantId?: string, currentChatId?: string): string {
    const id = generateId();
    this.service.createSession(assistantId, id, currentChatId);
    return id;
  }

  createHistoryMessage(historyItem: Chats.ChatHistoryItem) {
    this.service.createHistoryItem(historyItem);
  }

  convertToAnswerResources(resources: Search.ResultResourceItem[]): Chats.MessageResource[] {
    const updatedResources: Chats.MessageResource[] = (resources || []).map((r) => ({
      appId: r.resource?.appId,
      externalId: r.resource?.externalId,
    }));
    return updatedResources;
  }

  generateEmptyChat(assistantId?: string): Chats.Chat {
    return { id: NEW_CHAT_ID, chatHistory: [], assistantId };
  }

  private handleChatRoute() {
    this.routerService.activeRoute$
      .pipe(
        filter((currentRoute: ActivatedRoute) => {
          return !this.shouldResetChat(currentRoute);
        })
      )
      .subscribe(async (currentRoute) => {
        const globalAssistantId = await this.hubService.globalAssistantId;
        const assistantId = globalAssistantId || currentRoute?.snapshot?.params?.id;
        const chatData: CurrentChatData = await this.initChatData(assistantId);
        if (chatData) {
          const currentChat = this.currentChat;
          const chatChanged =
            !currentChat ||
            currentChat.assistant?.id != chatData.assistant?.id ||
            currentChat?.chatSession?.id != chatData?.chatSession?.id;
          if (chatChanged) {
            this.currentChat = { ...chatData };
            this.updateAnswersResources(chatData.latestChatSession || chatData.chatSession);
          }
        }
      });
  }

  private async initChatData(assistantId?: string): Promise<CurrentChatData> {
    const tempChatSession = this.getTempChatSession();
    const chatSession = cloneDeep(tempChatSession) || this.generateEmptyChat(assistantId);
    const chatData: CurrentChatData = { chatSession };
    const draftQuery = this.localTempQuery;
    if (draftQuery) {
      chatData.draftQuery = draftQuery;
      this.localTempQuery = null;
    }
    if (!tempChatSession) {
      const latestChatSession = await this.findChatByAssistant(assistantId);
      chatData.latestChatSession = latestChatSession;
    }
    const assistants = await firstValueFrom(this.assistantsForChat$);
    const assistant = assistants?.[assistantId || undefined];
    chatData.assistant = assistant;
    return chatData;
  }

  private getTempChatSession(assistantId?: string) {
    if (this.localTempChat && this.localTempChat.assistantId == assistantId) {
      const localTempChat = this.localTempChat;
      this.localTempChat = null;
      return localTempChat;
    }
    const tempChatFromStorage = this.extractSessionFromStorage();
    if (!tempChatFromStorage || tempChatFromStorage.assistantId != assistantId) {
      return;
    }
    localStorage.removeItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
    return tempChatFromStorage;
  }

  private extractSessionFromStorage() {
    try {
      const storage = localStorage.getItem(this.TEMP_CHAT_SESSION_STORAGE_KEY);
      if (!storage) {
        return;
      }
      return JSON.parse(storage) as Chats.Chat;
    } catch (error) {
      return;
    }
  }

  private shouldResetChat(currentRoute: ActivatedRoute): boolean {
    const currentPage = currentRoute?.snapshot?.data.id;
    const resetChat = currentPage !== CHAT_PAGE_PATH;
    if (resetChat) {
      this.currentChat = null;
      this.localTempChat = null;
      this.localTempQuery = null;
    }
    return resetChat;
  }

  private createMessageFromAnswer(answerItem: AnswerSearchItem, timestamp: number): Chats.AssistantMessage {
    const { state, text, searchId, resources, intent } = answerItem;
    if (state === 'NoResults') {
      return { state: Chats.AssistantMessageState.NoResultsFound, timestamp };
    }
    const updatedResources = this.convertToAnswerResources(resources);
    const answer: Chats.AssistantMessage = {
      content: text,
      state: state === 'RephraseRequired' ? Chats.AssistantMessageState.RephraseRequired : Chats.AssistantMessageState.ResultsAvailable,
      resources: updatedResources,
      searchId,
      timestamp,
    };
    if (intent) {
      answer.intent = intent;
    }
    return answer;
  }

  private async findChatByAssistant(assistantId?: string): Promise<Chats.Chat> {
    const chats = await firstValueFrom(this.all$);
    const chat = chats[assistantId || undefined];
    return chat;
  }

  private updateAnswersResources(currentChat: Chats.Chat) {
    const externalResources = (currentChat?.chatHistory || [])
      .map((h) => h?.assistantMessage?.resources?.map((r) => r?.externalId) || [])
      .filter((r) => !!r)
      .flat();
    this.chatResourcesService.updateRemoteResources(externalResources);
  }
}
