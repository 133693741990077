import { Chats } from '@local/client-contracts';
import { UiIconModel } from '@local/ui-infra';

export type AssistantChatData = {
  id: string;
  emoji?: string;
  name?: string;
  description?: string;
  icon?: UiIconModel;
  canAccess?: boolean;
  isDefault?: boolean;
};

export type CurrentChatData = {
  chatSession: Chats.Chat;
  latestChatSession?: Chats.Chat;
  draftQuery?: string;
  assistant?: AssistantChatData;
};
export const NEW_CHAT_ID = 'new';
